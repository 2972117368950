@media screen and (max-width: 1080px) {
    article {
        padding: 1em;
    }

    #projects {
        ul {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    footer {
        display: block;
        padding: 1.5em 0 0 0;

        .totop {
            width: 100%;

            .icon {
                text-align: center;

                svg {
                    width: 32px;
                    height: 26px;
                }
            }

            &:hover {
                transform: none;
                background: #333;
            }
        }

        .legal {
            transform: none;
            padding: 1em;
        }
    }
}

@media screen and (max-width: 750px) {
    header {
        padding: 1.5em 1em;

        div {
            margin: 0;
            width: 150px;
        }
    }

    img {
        cursor: default;
    }

    table {
        .prompt {
            cursor: default;
        }
    }

    article {
        padding: 1em;
    }

    #projects {
        ul {
            grid-template-columns: 1fr;
        }
    }
}