/*!
* __    _____ _____      __ _____ _____ ____  _____ _____
* |  |  |  _  | __  |  __|  |   | |   __|    \| __  | __  |
* |  |__|     | __ -|_|  |  | | | |__   |  |  | __ -|    -|
* |_____|__|__|_____|_|_____|_|___|_____|____/|_____|__|__|
*
* LAB.JNSDBR
* Experiments by Jens de Boer
*
* If you got a job to offer or you're interested to work with me,
* feel free to drop me a line: hello@jnsdbr.de
*
* @version 4.1
* @author Jens de Boer <jens@jnsdbr.de>
*/

@charset 'UTF-8';
@use 'sass:meta';
@include meta.load-css('base');
@include meta.load-css('fonts');
@include meta.load-css('basiclightbox');

* {
	box-sizing: border-box;
}

body {
    background: #111;
	color: var(--font-color);
	font-family: var(--default-font), sans-serif;
	font-size: 100%;
	line-height: 150%;
	letter-spacing: 0.025em;
	margin: 0;
}

h1, h2, h3, h4 {
	font-family: var(--headline-font);
	letter-spacing: 0;
	font-weight: normal;
	text-transform: uppercase;
}

a {
	color: var(--font-color);
	text-decoration: none;
	transition: color .2s linear;
}

a:hover {
    color: var(--ui-color);
}

header {
    display: flex;
    padding: 3em;

    h1 {
        flex: 1;
        margin: 0;
        padding: 0.2em 0 0 0;
        text-transform: uppercase;
        font-size: 1.5em;
        letter-spacing: 0.2em;
    }

    div {
        font-size: 120%;
        display: inline-block;
        margin-right: 1em;
        font-weight: var(--menu-font-weight);
    }
}

svg {
	fill: #fff;
	width: 100%;
}

article {
    margin: 0 auto;
    max-width: var(--max-width-small);
}

img {
    max-width: 100%;
    cursor: zoom-in;
}

.hidden {
	opacity: 0;
	transform: translateY(30px);
}


table {
    margin: 0 auto;
    max-width: 448px;

    td {
        vertical-align: top;
    }

    .prompt {
        cursor: copy;
    }
}

#projects {
	max-width: var(--max-width-small);

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        column-gap: 5em;
        row-gap: 5em;
        grid-template-columns: repeat(3, 1fr);

        li {
            text-align: center;
            transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

            table {
                text-align: left;
            }
        }
    }
}

footer {
	padding: 3em 3em 0 3em;
	width: 100%;
	display: flex;

    .totop {
        background: #333;
        display: block;
        color: #fff;
        width: var(--button-width);
        height: var(--button-height);
        transition: all .15s linear;
        position: absolute;
        padding: 0em .15em .1em .2em;

        &:hover {
            background: var(--ui-color);
            border: 0;

            div {
                transform: translateY(-2px);
            }
        }

        .name {
            display: none;
        }

        div {
            transition: all 0.15s linear;
        }
    }

    .legal {
        flex: 1;
        text-align: center;
    }
}

@include meta.load-css('mediaqueries');